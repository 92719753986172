<template>
  <div class="nio-school">
    <div class="school-game">
      <div class="title">“蔚来杯”大学生方程式系列赛事</div>
      <div class="sub-title">
        中国大学生方程式系列赛事创立于 2009
        年，由中国汽车工程学会主办。自2014年蔚来成立起，我们已连续九年支持中国大学生方程式系列赛事，
        为大学生提供一个展示、接触、学习前沿新能源技术的平台，为中国汽车工业全能型人才培养贡献力量。
      </div>
      <div
        class="more"
        style="color: #00bebe"
        @click="$router.push('/equation')"
      >
        了解更多
        <img
          src="../../assets/img/detail-icon.png"
          alt=""
          style="width: 26.5px; margin-top: -5px"
        />
      </div>
      <div class="video-play">
        <video
          controls
          ref="video"
          class="abs"
          src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/蔚来校园视频.mp4"
          preload
        ></video>、
      </div>
    </div>

    <div class="school-activity-wrap">
      <div class="school-activity">
        <div class="title">科研合作</div>
        <div class="activity-subTitle">
          依托蔚来与高校的科研合作项目，聚焦行业核心技术问题，校企双方联合培养科研型人才。
        </div>
        <ul class="activity-list-wrap">
          <li class="activity-list activity-list-left">
            <img src="../../assets/img/activity-1.jpg" alt="" />
            <p class="activity-list-title">产学课程共建项目</p>
            <p class="activity-list-sub-title">
              针对整车工程、电机、电控、电池、智能网关、智能座舱、智能驾驶等技术领域，与高校共创特色课程与教学素材，实施“校企双导师制”，共同进行理论和实践的技术指导，参与企业项目的研发。
            </p>
          </li>
          <li class="activity-list">
            <img src="../../assets/img/activity-2.png" alt="" />
            <p class="activity-list-title">前沿领域科研合作</p>
            <p class="activity-list-sub-title">
              与高校共建科研合作平台，面向国家智能汽车与新能源技术发展的重点领域，开展前沿科学问题、重大科技问题和战略高新技术问题的联合攻关。
            </p>
          </li>
        </ul>
      </div>
      <div class="school-activity">
        <div class="title">走进校园</div>
        <div class="activity-subTitle">
          通过校园宣讲会、企业OpenDay、校园大使等项目，与同学们面对面交流行业发展、技术趋势与求职选择。
        </div>
        <ul class="activity-list-wrap">
          <li class="activity-list activity-list-3">
            <img src="../../assets/img/activity-5.jpg" alt="" />
            <p class="activity-list-title">校园宣讲会</p>
            <p class="activity-list-sub-title">
              每年校招季，蔚来深入全国高校，与在校学子面对面交流，为广大应届毕业生搭建了解行业与企业的契机。
            </p>
          </li>
          <li class="activity-list activity-list-3">
            <img src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/蔚来校园-走进校园-企业OpenDay.jpg?imageView2/interlace/1" alt="" />
            <p class="activity-list-title">企业OpenDay</p>
            <p class="activity-list-sub-title" style="margin-right: 18px">
              蔚来通过企业开放日，与海内外高校加强交流合作、进行资源共享，邀请师生们走进蔚来办公室、蔚来中心、蔚来先进制造基地，用不同的互动形式让师生们实地感受用户企业的定义。
            </p>
          </li>
          <li class="activity-list">
            <img
              src="../../assets/img/activity-7.jpg"
              alt=""
              style="height: 253px"
              :style="{ objectFit: 'cover', objectPosition: 'center' }"
            />
            <p class="activity-list-title">校园大使</p>
            <p class="activity-list-sub-title">
              邀请在校同学成为蔚来“校园大使”，成为“蔚来故事”的讲述者，“蔚来价值”的传播者。
            </p>
          </li>
        </ul>
      </div>
      <div class="school-activity">
        <div class="title">实习项目</div>
        <div class="activity-subTitle">
          与高校共建实习就业基地、技能人才定向培养计划，帮助在校大学生深入企业拓展视野、提升技能、快速转换角色。
        </div>
        <ul class="activity-list-wrap">
          <li class="activity-list activity-list-left">
            <img src="../../assets/img/activity-3.png" alt="" />
            <p class="activity-list-title">就业实习基地</p>
            <p class="activity-list-sub-title">
              与高校合作共建就业实训基地，为合作高校毕业生提供实习岗位开展实践指导，在实践中带教，提升综合技能。
            </p>
          </li>
          <li class="activity-list">
            <img src="../../assets/img/activity-4.png" alt="" />
            <p class="activity-list-title">技能人才定向培养项目</p>
            <p class="activity-list-sub-title">
              采用校内结合厂内定制化培养模式，将产业先进专业知识技能提前纳入校内教学中，通过实践学习深入领会企业文化，帮助同学更快速转变角色，融入团队。
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nioSchool",
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style scoped>
.nio-school {
  background: #f7f8fa;
}

.school-game {
  width: 1000px;
  margin: 0 auto;
  text-align: center;
  padding-top: 90px;
  padding-bottom: 90px;
}

.title {
  font-size: 36px;
  color: #000000;
  line-height: 57.1px;
  margin-bottom: 8px;
  text-align: center;
}

.sub-title {
  font-size: 14px;
  line-height: 22.2px;
  margin-bottom: 14px;
  width: 80%;
  margin-left: 10%;
}

.video-play {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
  width: 1000px;
  height: 482px;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  left: 0;
  top: 0;
}

.more {
  font-size: 16px;
  color: #040b29;
  line-height: 36.66px;
  margin-bottom: 28px;
  cursor: pointer;
}

.school-activity-wrap {
  background: #ffffff;
  padding-bottom: 110px;
}

.school-activity {
  width: 1200px;
  margin: 0 auto;
  padding-top: 90px;
  /*text-align: center;*/
}

.activity-subTitle {
  font-size: 14px;
  line-height: 22.2px;
  margin-bottom: 60px;
  text-align: center;
}

.activity-list-wrap {
  display: flex;
}

.activity-list {
  flex: 1;
  box-shadow: 0 0 15px 0 rgba(24, 26, 32, 0.08);
  padding-bottom: 24px;
}

.activity-list-3 {
  margin-right: 31.97px;
}

.activity-list-left {
  margin-right: 40px;
}

.activity-list img {
  width: 100%;
  height: 356px;
}

.activity-list-3 img {
  height: 253px;
}

.activity-list-title {
  font-size: 18px;
  color: #000000;
  line-height: 28.55px;
  margin-left: 24px;
  margin-top: 24px;
}

.activity-list-sub-title {
  font-size: 12px;
  color: #000000;
  line-height: 19.03px;
  margin-left: 24px;
  margin-top: 12px;
  margin-right: 24px;
}
</style>
